/* import the fontawesome core */
import { config, library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faCalendar,
  faCaretDown,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle as fasCircle,
  faCog,
  faCogs,
  faEdit,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faHome,
  faLineChart,
  faList,
  faMap,
  faPhone,
  faPlus,
  faRefresh,
  faSearch,
  faSignOut,
  faTimes,
  faTrash,
  faUpload,
  faUser,
  faUserPlus,
  faUsers,
  faWrench,
    faUsersGear,
    faFileAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle as farCircle,
  faFileCode,
  faFileExcel,
  faFloppyDisk,
  faImage,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

/* add icons to the library */
library.add(
  faLineChart,
  faHome,
  faSquare,
  faPhone,
  faCheck,
  faTimes,
  faCaretDown,
  faUsers,
  faFileExcel,
  faFloppyDisk,
  faList,
  farCircle,
  fasCircle,
  faMap,
  faWrench,
  faCog,
  faCogs,
  faUpload,
  faUserPlus,
  faRefresh,
  faEdit,
  faImage,
  faTrash,
  faFileCode,
  faPlus,
  faArrowLeft,
  faSearch,
  faSignOut,
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faUser,
  faEnvelopeOpenText,
  faEnvelopeOpen,
    faUsersGear,
    faFileAlt
);
